@font-face {
  font-family: "Brand-font";
  src: url(./components/fonts/Raleway-VariableFont_wght.ttf) format("truetype");
}

@font-face {
  font-family: "Content-font";
  src: url(./components/fonts/Montserrat-Regular.ttf) format("truetype");
}

/* navbar */

.navbar {
  z-index: 1;
}

.navbar-brand {
  color: rgb(255, 255, 255);
  text-transform: uppercase !important;
  text-decoration: none !important;
  display: inline-block !important;
  margin-right: 170px;
  padding: 10px 20px;
  position: relative !important;
}

ul {
  margin: auto !important;
  padding: 0;
  list-style: none;
  display: table;
  width: 80%;
  text-align: center;
}

.active {
  font-weight: 400;
}

.navbar-nav li {
  padding: 15px 5px !important;
}
.navbar-nav a {
  color: rgb(255, 255, 255);
  font-size: 17px;
  text-transform: uppercase !important;
  text-decoration: none;
  letter-spacing: 0.15em;
  display: inline-block !important;
  padding: 10px 20px;
  position: relative !important;
}

.navbar-nav a:hover {
  color: #000;
}

.nav-link a::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.nav-link a:hover::before {
  transform: scaleX(1);
}
/* nav end */

/* homepage search*/
.black-bg-wrap {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(46, 46, 46, 1) 100%
  );
  min-height: 500px;
  padding: 100px 0;
  width: 100%;
}

.brand-text {
  width: 60%;
}
.brand-text span {
  font-family: "Brand-font";
  color: white;
  display: block;
  text-align: center;
  font-size: 32px;
}

.container-input {
  display: flex;
}

.url-input {
  margin-top: 60px;
  margin-bottom: 20px;
  width: 79%;
  margin-right: 1%;
}

#analyze-button {
  position: relative;
  width: 40%;
  margin-left: 240px;
  margin-bottom: 60px;
}

.example-text {
  font-family: "Content-font";
  text-align: center;
  color: white;
}

.sort-button {
  width: 20%;
  margin: auto;
  margin-top: 60px;
  margin-bottom: 20px;
}

.mantine-select-label {
  color: white !important;
}

/* homepage search end */

/* homepage about start */

.homepage-about-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 400px;
  padding: 90px 0;
}

.row {
  width: 60%;
}

.row-hpabout {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 70%;
}

.hp-about-title {
  font-family: "Brand-font";
  text-align: center;
}

.divider {
  position: relative;
  border: solid 1px black;
  margin: auto;
  width: 30% !important;
  color: #000000;
  background: rgb(0, 0, 0);
  height: 1px;
}

.homepage-about-container span {
  font-size: 17px;
  padding-top: 60px;
  font-family: "Content-font";
  text-align: center;
}

/* homepage about end */

/* footer start */

.container-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 90%; */
  padding: 30px;
}

.container-footer span {
  display: block;
  color: #00000065;
}

/* footer end */

/* about start */
.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0;
  /* height: 800px; */
  width: 100%;
}

.about-row {
  margin-top: 50px;
  width: 60%;
}

.about-container h1 {
  font-family: "Brand-font";
  font-weight: 600;
  font-size: 32px;
}

.about-row span {
  position: relative;
  font-family: "Content-font";
  display: block;
  margin-top: 30px;
}

.divider-about {
  position: relative;
  border: solid 1px black;
  width: 12%;
  color: #000000;
  background: rgb(0, 0, 0);
  height: 1px;
}

.divider-privacy {
  position: relative;
  border: solid 1px black;
  width: 15%;
  color: #000000;
  background: rgb(0, 0, 0);
  height: 1px;
}

.divider-subreddits {
  position: relative;
  border: solid 1px black;
  width: 25%;
  color: #000000;
  background: rgb(0, 0, 0);
  height: 1px;
}
/* about end */

/* subreddit start */
.subreddit_list-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* height: 1100px; */
  width: 100%;
}

.subreddit-container {
  display: flex;
  /* flex-direction: row; */
  /* justify-content: space-between; */
}

.gray-container {
  box-shadow: 0px 2px 5px rgb(0 0 0 / 0.2);
  border-radius: 25px;
  background: #f0f0f08a;
  padding: 20px;
  flex: 33%;
  margin: 0 1%;
}

.divider-leftleaning {
  position: relative;
  border: solid 1px black;
  width: 16%;
  color: #000000;
  background: rgb(0, 0, 0);
  height: 1px;
}

.divider-rightleaning {
  position: relative;
  border: solid 1px black;
  width: 28%;
  color: #000000;
  background: rgb(0, 0, 0);
  height: 1px;
}

.subreddit_list-container2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
}

/* 404 start */
.notfound-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 70vh;
  width: 100%;
  margin-bottom: 50px;
}

.notfound-wrap span {
  margin-top: 50px;
  color: black;
}

.notfound-wrap > a {
  text-decoration: underline !important;
  color: blue;
}

/* data display start */
.black-data-wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(46, 46, 46, 1) 100%
  );
  height: 300px;
}

.data_displaywrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 2200px;
}

.data-row,
.data-chart-row {
  width: 70%;
}

.data-row h1 {
  font-size: 52px;
  margin-bottom: 20px;
}

.data_title {
  font-family: "Brand-font";
  color: rgb(0, 0, 0);
  display: block;
  font-size: 32px;
}

.data-textcontent {
  color: rgb(0, 0, 0);
  font-family: "Content-font";
  margin-top: 50px !important;
}

.divider-data {
  position: relative;
  border: solid 1px black;
  width: 24%;
  color: #000000;
  background: rgb(0, 0, 0);
  height: 1px;
  margin-bottom: 30px;
}

.center-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.data-container {
  width: 400px;
  height: 400px;
}

.data-textcontent-nm {
  color: rgb(0, 0, 0);
  font-family: "Content-font";
  margin-top: 20px;
}
.word-break {
  overflow-wrap: break-word;
  word-wrap: break-word;

  /* Adds a hyphen where the word breaks */
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

/* loadingpage start */
/* Media query for css  */
@media screen and (max-width: 1262px) {
  .gray-container {
    flex: 50%;
  }
}
@media screen and (max-width: 1100px) {
  .container-footer {
    border-top: 1px solid #ddd;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  .navbar-brand {
    margin: 0;
  }

  .subreddit_list-container,
  .subreddit_list-container-2 {
    width: 100% !important;
    margin: 0 !important;
  }
  .gray-container {
    padding: 10px;
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 990px) {
  .subreddit-container {
    flex-direction: column;
  }
}

@media screen and (max-width: 890px) {
  .about-row span {
    width: 100%;
  }
  .data-chart-row {
    width: 100%;
    padding: 10px;
  }
  .data_displaywrapper .data-row {
    width: 84%;
  }

  .paper-d1-0-2-3 {
    padding: 10px 0 !important;
  }
  tspan {
    font-size: 14px;
  }
  .about-row h1 {
    font-size: 1rem;
  }
  .container-input {
    flex-direction: column;
    margin-top: 50px;
  }
  .brand-text span {
    font-size: 28px;
  }
  .url-input,
  .sort-button {
    width: 100%;
    padding: 0;
    margin: 10px 0;
  }

  .brand-text {
    width: 84%;
  }
  #analyze-button {
    margin: 20px 0 80px 0;
    width: 100%;
  }
}
@media screen and (max-width: 575px) {
  .row {
    width: 80%;
  }
  .pie-chart .recharts-wrapper {
    height: 330px !important;
    text-align: center;
  }
  .pie-chart .recharts-wrapper > svg.recharts-surface {
    height: 300px;
    width: 65%;
    overflow: visible;
  }
  .pie-chart .recharts-responsive-container {
    height: 280px !important;
  }
}
